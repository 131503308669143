/************************************************************************************/
/*                            Custom TABLE change - START                          */
/************************************************************************************/
.ant-table-wrapper .ant-table-tbody > tr > td {
  padding: 0 5px !important;
}

/* :where(.css-dev-only-do-not-override-txh9fw).ant-table-wrapper .ant-table-tbody > tr > td {
  padding: 0 5px !important;
} */

.ant-table-tbody > tr > td {
  padding: 0 2px !important;
}

.ant-table-wrapper .ant-table-thead>tr>th  {
  background-color: none !important;
}

.table-orange .ant-table-thead > tr > th {
  background-color: #C55911 !important;
  color: #fff !important;
  font-weight: bold !important;
  border: 0.5px #f8ba91 solid !important;
} 

.table-orange .custom-orange {
  background-color: #FCE5D7;
}

/************************************************************************************/
/*                            Custom TABLE change - START                          */
/************************************************************************************/

.no-border .ant-table-tbody > tr > td {
  border: none
}

/************************************************************************************/
/*                            Custom TABLE change - START                          */
/************************************************************************************/

.ant-table-wrapper td.ant-table-column-sort {
  background: none !important;
}

.custom-blue-100 {
  background-color: #E4F0FC;
}

.custom-blue-200 {
  background-color: #D3E9FF;
}

.custom-blue {
  background-color: #C0DAF3;
}

.custom-blue-light {
  background-color: #E4F0FC;
}

.table-medium {
  padding: 0 0 0 0px !important;
  margin: 0 !important;
}

.table-medium .ant-table-cell {
  font-size: 13px !important;
}

/* all table headers to black and centered */
.table-medium th {
  color: #000 !important;
  text-align: center !important;
}

.table-medium .ant-table-head {
  color: #000 !important;
}

.tab-medium .ant-tabs-tab {
  padding: 0 !important;
}

/************************************************************************************/
/*                            Custom BUTTON change - START                          */
/************************************************************************************/

.custom-button-primary {
  border-radius: 0;
  background-color: #374151 !important;
  color: white;
}

.custom-button-default {
  border-radius: 0;
}

/************************************************************************************/
/*                             Custom BUTTON change - END                           */
/************************************************************************************/

/************************************************************************************/
/*                             Custom MODAL change - START                          */
/************************************************************************************/

.no-tab-header .ant-tabs-nav {
  display: none !important;
}

/************************************************************************************/
/*                               Custom TAB change - END                            */
/************************************************************************************/

/************************************************************************************/
/*                             Custom MODAL change - START                          */
/************************************************************************************/
.custom-modal-overlay {
  position: fixed;
  top: 70%;
  left: 0;
  width: 100%;
  background-color: none; /* Translucent background */
  z-index: 9999; /* Ensure overlay is on top of other elements */
}

.custom-modal-overlay .ant-table-body {
  height: 300px;
}
/************************************************************************************/
/*                             Custom MODAL change - START                          */
/************************************************************************************/


/************************************************************************************/
/*                             Custom TABLE change - START                          */
/************************************************************************************/
:where(.css-dev-only-do-not-override-txh9fw).ant-table-wrapper .ant-table-container table > thead > tr:first-child > :first-child {
  border-start-start-radius: 0 !important;
}

:where(.css-txh9fw).ant-table-wrapper .ant-table-container table > thead > tr:first-child > :first-child {
  border-start-start-radius: 0 !important;
}

:where(.css-dev-only-do-not-override-txh9fw).ant-table-wrapper .ant-table-container table > thead > tr:first-child > :first-child {
  border-start-start-radius: 0 !important;
}

:where(.css-txh9fw).ant-table-wrapper .ant-table-container table > thead > tr:first-child > :first-child {
  border-start-start-radius: 0 !important;
}

:where(.css-dev-only-do-not-override-txh9fw).ant-table-wrapper .ant-table-thead > tr > th {
  /* background-color: #f0f0f0 !important; */
}

:where(.css-txh9fw).ant-table-wrapper .ant-table-thead > tr > th {
  /* background-color: #f0f0f0 !important; */
}

.custom-gray {
  background-color: #F8F8F8;
}

/************************************************************************************/
/*                            Custom TABLE change - End                          */
/************************************************************************************/


/************************************************************************************/
/*                             Custom TAB change - START                            */
/************************************************************************************/
/* No radius */
:where(.css-dev-only-do-not-override-txh9fw).ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab, :where(.css-dev-only-do-not-override-txh9fw).ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 0;
}
:where(.css--txh9fw).ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab, :where(.css-txh9fw).ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 0;
}

/* Custom color */
:where(.css-dev-only-do-not-override-txh9fw).ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active {
  border-top: 3px #374151 solid !important;
  border-radius: 0 !important;
  border: none;
  background-color: #FFF !important;
}
:where(.css-txh9fw).ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active {
  border-top: 3px #374151 solid !important;
  border: none;
  border-radius: 0 !important;
}
:where(.css-dev-only-do-not-override-txh9fw).ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
  background-color: #e8e8e8 !important;
}

/************************************************************************************/
/*                              Custom TAB change - END                             */
/************************************************************************************/

/************************************************************************************/
/*                             Custom TAB change - START                            */
/************************************************************************************/
.ant-tabs-nav-list {
  border-bottom: none !important;
}

.no-pagination .ant-pagination {
  display: none !important;
}

.custom-row-hover:hover td {
  background: rgb(255, 233, 110)!important;}

.table-blue th {
  font-size: 13px !important;
  border: 1px !important;
  border-color: white !important;
}

:where(.css-dev-only-do-not-override-1w4v3hc).ant-table-wrapper .ant-table-thead >tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  display: none !important;
}

.table-blue .ant.table-thead > tr > th::before {
  display: none !important;
}

.table-small .ant-table-cell {
  font-size: 13px !important;
}

/* all table headers to black and centered */
.table-small th {
  color: #000 !important;
  text-align: center !important;
}

.table-small .ant-table-head {
  color: #000 !important;
}

.table-blue .ant-table-thead > tr > th {
  background-color: #3278BE !important;
  color: #fff !important;
  font-weight: bold !important;
  border: 0.5px #C0DAF3 solid !important;
} 

:where(.css-dev-only-do-not-override-txh9fw).ant-table-wrapper td.ant-table-column-sort {
  background: none;
}

:where(.css-txh9fw).ant-table-wrapper td.ant-table-column-sort {
  background: none !important;
}
.main .ant-tabs-nav {
  margin: 0 0 12px 0 !important;
}

.table-small .ant-table-cell {
  padding: 0 0 0 0px !important;
  margin: 0 !important;
}

.table-small div::-webkit-scrollbar {
  height: 7px;
  width: 7px;
}

.table-small div::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.gray .ant-select-selection-item {
  color: #6f6f6f !important;
  font-size: 12px;
  font-weight: bold;
}

.gray .ant-select-selection-item {
  font-weight: bold;
}

.tab-small .ant-tabs-tab {
  padding: 0 !important;
}

.no-padding .ant-menu-item {
  padding-left: 8px !important;
}

:where(.css-dev-only-do-not-override-1w4v3hc).ant-table-wrapper td.ant-table-column-sort {
  background: none;
}
